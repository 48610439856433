import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import SEO from "../components/Seo"
import "./index.scss"
import { useNavState, useSetRouteNavState } from "../hooks/NavState"
import { TransitionProps } from "../components/ITransition"
import Copyright from "../components/Copyright"
import { isBreakpointMore, useWindowSize } from "../hooks/WindowSize"
import {
  PrismicQuery,
  PrismicEdges,
  PrismicImage,
  PrismicFluidImage,
} from "../types/prismic"
import LazyImage from "../components/LazyImage"

type PageEdge = {
  _meta: {
    uid: string
  }
  headline: string
  image: PrismicImage
  imageSharp?: PrismicFluidImage
  meta_description: string
}

type AllHomepages = {
  allHomepages: PrismicEdges<PageEdge>
}

export const query = graphql`
  query {
    prismic {
      allHomepages {
        edges {
          node {
            headline
            meta_description
            image
          }
        }
      }
    }
  }
`

const AnimatableIndexContent: React.FC = ({ children }) => {
  const navState = useNavState()
  return (
    <AnimatePresence>
      {navState === "expanded" && (
        <>
          <motion.div
            className="home__copy-bg"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="home__copy"
            animate={{ translateX: 0, opacity: 1, transition: { delay: 0.2 } }}
            exit={{ translateX: "-25%", opacity: 0 }}
          >
            {children}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

const StaticIndexContent: React.FC = ({ children }) => {
  return (
    <>
      <div className="home__copy-bg" />
      <div className="home__copy">{children}</div>
    </>
  )
}

interface PageProps extends PrismicQuery<AllHomepages>, TransitionProps {}
const IndexPage: React.FC<PageProps> = ({
  transitionStatus,
  data: prismicData,
}) => {
  // check our data from Prismic
  const data = prismicData.prismic.allHomepages.edges?.[0]?.node
  if (!data) return null

  // subscribe to window size and set the nav states
  const windowSize = useWindowSize()
  const navState = useNavState()
  useSetRouteNavState("expanded", transitionStatus, {
    toggle: [
      {
        icon: "open",
        state: "expanded",
        to: "open",
      },
      {
        icon: "close",
        state: "open",
        to: "expanded",
      },
    ],
  })

  // Is the component for the copy going to be animatable or static?
  const CopyComponent = isBreakpointMore("lg", windowSize)
    ? AnimatableIndexContent
    : StaticIndexContent

  // set some animation variants depending on mobile or not
  const variants = isBreakpointMore("lg", windowSize)
    ? {
        enter: { translateX: 0 },
        exit: { translateX: "-50%", transition: { delay: 0.2 } },
      }
    : { enter: {}, exit: {} }

  const bgVariants = { enter: { opacity: 1 }, exit: { opacity: 0 } }

  // determine what to animate
  const animate = navState === "expanded" ? "enter" : "exit"

  return (
    <div className="theme-dark">
      <SEO title="Home" description={data.meta_description} />
      <div className="home__content">
        <motion.div
          className="home__copy-container level--2"
          variants={variants}
          animate={animate}
          key={
            isBreakpointMore("lg", windowSize) ? "d-container" : "m-container"
          }
        >
          <CopyComponent>
            <div />
            <h1 className="heading--1 text--primary">
              {RichText.asText(data.headline)}
            </h1>
            <div className="hide--md-down">
              <Copyright />
            </div>
          </CopyComponent>
        </motion.div>
        <motion.div
          className="home__image-wrap flex--fill level--1"
          variants={bgVariants}
          initial={{ opacity: 0 }}
          animate={transitionStatus === "exiting" ? "exit" : "enter"}
        >
          <div className="home_image">
            <LazyImage image={data.image} background />
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default IndexPage
